import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import { Link as RouterLink, useParams } from "react-router-dom";
import PhoneField from "../../common/component/PhoneField";
import Images from "../../common/component/Images";
import { TIME_WINDOWS } from "../../common/component/constants";
import { useNavigate } from "react-router";
import PlacesAutocomplete from "../../common/component/PlacesAutocomplete";
import EmailInput from "../../common/component/EmailInput";

export default function Request(props) {
  const { reqId } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [servings, setServings] = useState("");
  const [budget, setBudget] = useState("");
  const [details, setDetails] = useState("");
  const [pics, setPics] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [nogo, setNogo] = useState("");
  const [experiment, setExperiment] = useState("");
  const [notes, setNotes] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/admin/requests/${reqId}`).then((res) => {
      setTitle(res.data.title);
      setServings(res.data.servings);
      setBudget(res.data.budget);
      setDetails(res.data.details);
      setPics(res.data.pics);
      setDate(res.data.date);
      setTime(res.data.time);
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
      setEventLocation(res.data.eventLocation);
      setEmail(res.data.email);
      setPhone(res.data.phone);
      setStatus(res.data.status);
      setNogo(res.data.nogo);
      setExperiment(res.data.experiment);
      setNotes(res.data.notes || "");
      setLoading(false);
    });
  }, [reqId]);

  const submit = (e) => {
    e.preventDefault();

    const payload = {
      title,
      servings,
      budget,
      details,
      pics,
      date,
      time,
      firstName,
      lastName,
      location: eventLocation.description,
      eventLocation,
      email,
      phone,
      status,
      nogo,
      experiment,
      notes
    };

    bkstApi({
      method: "PUT",
      url: `/requests/${reqId}`,
      data: payload
    }).then((res) => {
      navigate(`/request/${reqId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box mb={1} mt={5}>
        <Container maxWidth="md">
          <Typography variant="overline" gutterBottom>
            Quote Request
          </Typography>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Paper elevation={0}>
              <Box p={2}>
                <form onSubmit={submit}>
                  <Grid container spacing={3}>
                    <Grid item xs={8} sm={3} align="center">
                      <Images pics={pics} edit={true} onChange={(pics) => setPics(pics)} folder={`img/r/${reqId}`} />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            name="title"
                            value={title}
                            variant="outlined"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <TextField
                            value={date}
                            variant="outlined"
                            fullWidth
                            id="date"
                            label="Date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="time-label">Time</InputLabel>
                            <Select value={time} labelId="time-label" label="Time" id="time" name="time" onChange={(e) => setTime(e.target.value)}>
                              {TIME_WINDOWS.map((i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <TextField
                            name="servings"
                            type="number"
                            value={servings}
                            variant="outlined"
                            fullWidth
                            id="servings"
                            label="Servings"
                            onChange={(e) => setServings(e.target.value)}
                          />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <TextField
                            name="budget"
                            type="number"
                            value={budget}
                            variant="outlined"
                            fullWidth
                            id="budget"
                            label="Budget"
                            onChange={(e) => setBudget(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            value={details}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={details.length / 50 + 1}
                            id="details"
                            label="Details"
                            name="details"
                            onChange={(e) => setDetails(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="overline">Customer</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={firstName}
                        name="firstName"
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={lastName}
                        name="lastName"
                        variant="outlined"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PlacesAutocomplete onChange={setEventLocation} value={eventLocation} label="Zip Code" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <EmailInput value={email} onChange={(val) => setEmail(val)} required={true} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PhoneField name="Phone" value={phone} onChange={(p) => setPhone(p)} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="overline">Admin</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          value={status}
                          labelId="status-label"
                          label="Status"
                          id="status"
                          name="status"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value="new">New</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="notified">Notified</MenuItem>
                          <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="nogo-label">NoGo Reason</InputLabel>
                        <Select
                          value={nogo}
                          labelId="nogo-label"
                          label="NoGo Reason"
                          id="nogo"
                          name="nogo"
                          onChange={(e) => setNogo(e.target.value)}
                          disabled={status !== "closed"}
                        >
                          <MenuItem value="budget">Budget</MenuItem>
                          <MenuItem value="coverage">Coverage</MenuItem>
                          <MenuItem value="duplicate">Duplicate</MenuItem>
                          <MenuItem value="offline">Offline</MenuItem>
                          <MenuItem value="timing">Last Minute</MenuItem>
                          <MenuItem value="stop">Stop</MenuItem>
                          <MenuItem value="trust">Trust</MenuItem>
                          <MenuItem value="unknown">Unknown</MenuItem>
                          <MenuItem value="unreachable">Unreachable</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="experiment-label">A/B Experiment</InputLabel>
                        <Select
                          value={experiment}
                          labelId="experiment-label"
                          label="A/B experiment"
                          id="experiment"
                          name="experiment"
                          onChange={(e) => setExperiment(e.target.value)}
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="leakproof">Leakproof</MenuItem>
                          <MenuItem value="leaky">Leaky</MenuItem>
                          <MenuItem value="trusty">Trusty</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          value={notes}
                          variant="outlined"
                          fullWidth
                          id="notes"
                          label="Notes"
                          name="notes"
                          multiline
                          rows={notes.length / 50 + 1}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box mt={4} align="right">
                    <Button variant="text" component={RouterLink} to={`/request/${reqId}`} style={{ marginRight: "25px" }}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Slide>
        </Container>
      </Box>
    );
  }
}
