import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import { isEmailValid } from "./util";

export default function EmailInput(props) {
  const [hasError, setHasError] = useState(false);
  const ref = useRef(null);

  const onBlur = () => {
    if (props.value) {
      const isValid = isEmailValid(props.value);

      // only force focus the 1st time
      if (!hasError && !isValid) {
        ref.current.focus();
      }

      setHasError(!isValid);
    }
  };

  const cleanupValue = (str) => {
    return str.replace(/\s/g, "");
  };

  const onChange = (e) => {
    console.log("inside onChange");
    if (hasError) {
      console.log("check validity");
      // check validity realtime
      const isValid = isEmailValid(e.target.value);
      setHasError(!isValid);
    }
    props.onChange(cleanupValue(e.target.value));
  };

  return (
    <TextField
      inputRef={ref}
      value={props.value}
      variant="outlined"
      fullWidth
      id="email"
      label="Email"
      name="email"
      onChange={onChange}
      onBlur={onBlur}
      required={props.required}
      error={hasError}
      helperText={hasError && `Invalid Email`}
    />
  );
}
