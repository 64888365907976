import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function PhoneField(props) {
  const [number, setNumber] = useState(props.value);

  const onChange = (phone) => {
    setNumber(`+${phone}`);
  };

  const checkValidityonBlur = (e) => {
    if (number?.length >= 12) {
      props.onChange(number);
    } else {
      setNumber("+1");
      props.onChange("");
    }
  };

  const onPasteValue = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    if (paste) {
      // cleanup number
      paste = paste.replace(/[^\d+]/g, "");

      if (paste.startsWith("+")) {
        setNumber(paste);
        e.preventDefault();
      } else if (paste.length > 10) {
        setNumber(`+1${paste.slice(-10)}`);
        e.preventDefault();
      } else {
        // do nothing
      }
    }
  };

  return (
    <PhoneInput
      country={"us"}
      value={number}
      onChange={(phone) => onChange(phone)}
      onBlur={checkValidityonBlur}
      inputClass="phone"
      containerClass="phone"
      containerStyle={props.style || {}}
      specialLabel={props.name}
      countryCodeEditable={false}
      disabled={props.disabled}
      inputProps={{ onPaste: (e) => onPasteValue(e) }}
    />
  );
}
