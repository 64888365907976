import React from "react";
import { Alert, AlertTitle } from "@mui/material";

export default function NoGo(props) {
  const { nogo } = props;

  let title = props.title || "";
  let description = props.description || "";

  if(title && description){
    // already known
  } else if (nogo === "duplicate") {
    title = "Duplicate";
    description = "The customer may have requested multiple quotes.";
  } else if (nogo === "ordered") {
    title = "Order Placed";
    description = "The customer may have ordered a different quote.";
  } else if (nogo === "cxl") {
    title = "Event Canceled";
    description = "The customer may have indicated that their event has been canceled.";
  } else if (nogo === "rejected") {
    title = "Rejected by Customer";
    description = "The customer may have indicated that they are not going to order.";
  } else if (nogo === "unavailable") {
    title = "Unavailable";
    description = "You are unable to fulfill this order.";
  } else if (nogo === "stop") {
    title = "Unsubscribed";
    description = "Customer indicated that they're no longer interested.";
  } else if (nogo === "other") {
    title = "Other";
    description = "Reason unknown.";
  } else {
    title = nogo;
  }

  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  );
}
