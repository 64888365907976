import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Container, Grid, LinearProgress, Typography, Divider, Chip } from "@mui/material";
import bkstApi from "../../common/component/api";
import moment from "moment";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";

export default function LoanDetail(props) {
  const [loading, setLoading] = useState(true);
  const [loan, setLoan] = useState();
  const [repayments, setRepayments] = useState();

  const { loanId } = useParams();

  useEffect(() => {
    bkstApi(`/loan/${loanId}`).then((res) => {
      setLoan(res.data.loan);
      if (res.data.loan.repayment) {
        const arr = Object.values(res.data.loan.repayment).sort((o1, o2) => (o1.ts > o2.ts ? -1 : 1));
        setRepayments(arr);
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box mt={5} mb={2} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Avatar src={`${BAKER_PIC_FOLDER}/${loan.uid}/photo400.webp`} />
          <Typography variant="h3">
            Loan Details
          </Typography>
          <Chip label={loan.status} size="small"/>
        </Box>
        <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                LOAN AMOUNT
              </Typography>
              <Typography variant="body1">{currency(loan.loanAmount).format()}</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                FEE / INTEREST
              </Typography>
              <Typography variant="body1">{currency(loan.fee).format()}</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                TOTAL AMOUNT DUE
              </Typography>
              <Typography variant="body1">{currency(loan.total).format()}</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                PERIOD
              </Typography>
              <Typography variant="body1">{loan.period} days</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                AMOUNT REPAID
              </Typography>
              <Typography variant="body1">{currency(+loan.total - +loan.balance).format()}</Typography>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography variant="overline" color="textSecondary">
                OUTSTANDING BALANCE
              </Typography>
              <Typography variant="body1">{currency(loan.balance).format()}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} mb={2}>
          <Typography variant="h5" gutterBottom>
            Repayment Activity
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {loan.repaymentRate || 20}% of each transaction goes towards your loan repayment
          </Typography>
        </Box>
        {repayments && (
          <Box p={2} style={{ border: "1px solid #EEE", backgroundColor: "#fff" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="overline" color="textSecondary">
                  DATE
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  REPAYMENT AMOUNT
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="overline" color="textSecondary">
                  DETAILS
                </Typography>
              </Grid>
            </Grid>
            <Box mt={1} mb={2}>
              <Divider />
            </Box>
            {repayments.map((value, idx) => {
              return (
                <Grid key={idx} container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body2">{moment(value.ts).format("MMM DD")}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">{currency(value.amount).format()}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="text" color="primary" component={RouterLink} to={`/order/${value.orderId}`}>
                      view order
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
        <Box my={6}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            fullWidth
            target="_blank"
            href={`https://console.firebase.google.com/u/0/project/bakestreet-f55a0/firestore/databases/-default-/data/~2Floan~2F${loanId}`}
          >
            View in DB
          </Button>
        </Box>
      </Container>
    );
  }
}
