import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Container, FormControl, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import { useNavigate } from "react-router";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ConfigContext from "../../common/component/context/ConfigContext";
import LineItems from "../../common/component/lineitem/LineItems";
import QuoteFulfillmentEntry from "../../common/component/fulfillment/QuoteFulfillmentEntry";
import BDateTimePicker from "../../common/component/BDateTimePicker";
import ContactDetailsButton from "../../common/component/ContactDetailsButton";
import { getCustomerName, getDefaultQuoteExpiration } from "../../common/component/util";
import GenerateAutoQuotesV3 from "../../request/component/GenerateAutoQuotesV3";
import QuoteSearch from "../component/QuoteSearch";

export default function EditQuote(props) {
  const { user } = useContext(ConfigContext);
  let location = useLocation();

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [fulfillment, setFulfillment] = useState("");
  const [exdate, setExdate] = useState("");

  const [errors, setErrors] = useState([]);

  const { reqId } = useParams();
  const [request, setRequest] = useState("");
  const [quote, setQuote] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    bkstApi(`/admin/quote/${reqId}`).then((res) => {
      setRequest(res.data.request);
      setQuote(res.data.quote);

      setItems(
        res.data.quote.items || [
          {
            images: res.data.request.pics || [],
            customization: {},
            includeFees: true,
            quantity: "1",
            title: res.data.request.title,
            type: "Cake",
            price: 0,
            total: 0,
            description: res.data.request.details ? `We can accommodate your request "${res.data.request.details}"` : ""
          }
        ]
      );

      if (res.data.quote.fulfillment) {
        setFulfillment(res.data.quote.fulfillment);
      } else {
        setFulfillment({
          date: res.data.request.date,
          option: { pickup: {}, delivery: { includeFees: true } }
        });
      }

      if (res.data.quote?.status === "expired") {
        // update expiration if quote is expired
        setExdate(getDefaultQuoteExpiration(res.data.request.date));
      } else {
        setExdate(res.data.quote.exdate || getDefaultQuoteExpiration(res.data.request.date));
      }
      setLoading(false);
    });
  }, [reqId, location.key]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submit("send");
  };

  const submit = (mode) => {
    let payload = {
      mode,
      items,
      fulfillment,
      exdate,
      status: "active",
      autoQuote: true,
      appVersion: process.env.REACT_APP_VERSION
    };

    bkstApi.put(`/requests/${request.id}/quotes/${quote.id}`, payload).then((res) => {
      navigate(`/request/${reqId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box sx={{ minHeight: "100vh", backgroundColor: "#FBEEE6" }}>
        <Container maxWidth="sm">
          <Box py={6}>
            <form onSubmit={handleSubmit}>
              <Box textAlign={"center"}>
                <Typography variant="h3" gutterBottom>
                  Edit Quote
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Complete all your changes. Then tap the Send Quote button to save changes & notify the customer
                </Typography>
              </Box>
              <Box my={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                    <ContactDetailsButton
                      size="small"
                      name={getCustomerName({ name: request.name, firstName: request.firstName, lastName: request.lastName })}
                      phone={request.phone}
                      email={request.email}
                      verified={request.pv}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "4px", alignItems: "center", flexGrow: "1", justifyContent: "flex-end" }}>
                    <QuoteSearch q={quote.id} />
                    <GenerateAutoQuotesV3
                      request={request}
                      bakerId={quote.id}
                      onChange={(o) => {
                        let arr = items.filter(o => +o.price > 0);
                        arr.push(o);
                        setItems(arr);
                      }}
                    />
                  </Box>
                </Box>
                <LineItems items={items} onChange={(arr) => setItems(arr)} />
                {items.length > 0 && (
                  <Box>
                    <Box my={4}></Box>
                    <Typography variant="overline" color="textSecondary">
                      SHIPPING
                    </Typography>
                    <Paper elevation={0}>
                      <Box px={2} pt={3} pb={2}>
                        <QuoteFulfillmentEntry fulfillment={fulfillment} onChange={(o) => setFulfillment(o)} />
                      </Box>
                    </Paper>
                    <Box mt={4} mb={2}>
                      <Box>
                        <FormControl variant="outlined" fullWidth>
                          <BDateTimePicker
                            value={exdate || null}
                            label="Expiration"
                            onChange={(d) => setExdate(d)}
                            disablePast
                            required
                            autoOk
                            maxDateTime={(fulfillment?.date && moment(fulfillment.date).endOf("day").toDate()) || null}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    {errors && errors.length > 0 && (
                      <Box my={2}>
                        <Alert severity="error">{errors.join(". ")}</Alert>
                      </Box>
                    )}
                  </Box>
                )}
                <Box my={4}>
                  <Box sx={{ display: "flex", gap: "15px" }}>
                    <Button fullWidth size="small" variant="outlined" color="secondary" onClick={() => navigate(`/quote/${reqId}`)}>
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        items.reduce((acc, obj) => {
                          return +acc + +obj.total;
                        }, 0) === 0
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    );
  }
}
