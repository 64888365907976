import React, { useState, useEffect } from "react";
import { Box, Container, Chip, Grid, IconButton, InputAdornment, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "./api";
import SendIcon from "@mui/icons-material/Send";
import { getQueryParam } from "./util";

export default function Gemini(props) {
  const src = getQueryParam(window.location.search, "src");

  const [templates, setTemplates] = useState("");

  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [summary, setSummary] = useState("");

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    bkstApi(`/admin/gemini/prompt`).then((res) => {
      setTemplates(res.data);
    });
  }, []);

  const generateSummary = (response) => {
    console.log("->", response);

    if (response["2d_elements"] || response["3d_elements"]) {
      const summary = {
        figurineBasedLevel: 0,
        complexityBasedLevel: 0,
        TWO_D: {
          count: 0,
          surfaceArea: 0,
          cost: 0,
          effort: 0,
          lowComplexity: 0,
          mediumComplexity: 0,
          highComplexity: 0
        },
        THREE_D: {
          count: 0,
          surfaceArea: 0,
          cost: 0,
          effort: 0,
          lowComplexity: 0,
          mediumComplexity: 0,
          highComplexity: 0
        }
      };
      for (const decoration of response["2d_elements"]) {
        summary.TWO_D.count = +summary.TWO_D.count + decoration.count;
        summary.TWO_D.surfaceArea = +summary.TWO_D.surfaceArea + +decoration["surface_area"] * decoration.count;
        summary.TWO_D.cost = +summary.TWO_D.cost + decoration.cost;
        summary.TWO_D.effort = +summary.TWO_D.effort + decoration.effort;

        if (decoration.complexity === "low") {
          summary.TWO_D.lowComplexity = +summary.TWO_D.lowComplexity + decoration.count;
        } else if (decoration.complexity === "medium") {
          summary.TWO_D.mediumComplexity = +summary.TWO_D.mediumComplexity + decoration.count;
        } else if (decoration.complexity === "high") {
          summary.TWO_D.highComplexity = +summary.TWO_D.highComplexity + decoration.count;
        }
      }
      for (const decoration of response["3d_elements"]) {
        summary.THREE_D.count = +summary.THREE_D.count + decoration.count;
        summary.THREE_D.surfaceArea = +summary.THREE_D.surfaceArea + +decoration["surface_area"] * decoration.count;
        summary.THREE_D.cost = +summary.THREE_D.cost + decoration.cost;
        summary.THREE_D.effort = +summary.THREE_D.effort + decoration.effort;

        if (decoration.complexity === "low") {
          summary.THREE_D.lowComplexity = +summary.THREE_D.lowComplexity + decoration.count;
        } else if (decoration.complexity === "medium") {
          summary.THREE_D.mediumComplexity = +summary.THREE_D.mediumComplexity + decoration.count;
        } else if (decoration.complexity === "high") {
          summary.THREE_D.highComplexity = +summary.THREE_D.highComplexity + decoration.count;
        }
      }

      // handmade figurines?
      const handmadeFigurines = response["3d_elements"].filter((o) => o.type.includes("figurine") && o.manufactured === "handmade");
      const totalHandmadeFigurines = handmadeFigurines.reduce((acc, o) => +acc + +o.count, 0);

      if (totalHandmadeFigurines >= 3) {
        summary.figurineBasedLevel = 5;
      } else if (totalHandmadeFigurines > 1) {
        summary.figurineBasedLevel = 4;
      } else if (totalHandmadeFigurines === 1) {
        summary.figurineBasedLevel = 3;
      } else {
        // check 2D decorations
        // if any 2d decoration other than piping, then level 2
        const nonPipingDecoration = response["2d_elements"].filter((o) => !o.type.includes("piping"));
        if (nonPipingDecoration.length > 0) {
          summary.figurineBasedLevel = 2;
        } else {
          summary.figurineBasedLevel = 1;
        }
      }

      // Check complexity of decoration
      if (summary.THREE_D.highComplexity >= 3) {
        summary.complexityBasedLevel = 5;
      } else if (summary.THREE_D.highComplexity > 1) {
        summary.complexityBasedLevel = 4;
      } else if (summary.THREE_D.highComplexity === 1 || summary.TWO_D.highComplexity > 2) {
        summary.complexityBasedLevel = 3;
      } else {
        if (summary.TWO_D.highComplexity > 0 || summary.TWO_D.mediumComplexity > 0) {
          summary.complexityBasedLevel = 2;
        } else {
          summary.complexityBasedLevel = 1;
        }
      }

      setSummary(summary);
    }
  };

  const sendToGemini = (prompt) => {
    setLoading(true);
    setSummary("");

    setPrompt(prompt);

    bkstApi.post(`/gemini`, { img: src, prompt }).then((res) => {
      setResponse({ ...res.data.json, tags: res.data.tags });
      setEdit(false);
      setLoading(false);

      generateSummary(res.data.json);
    });
  };

  return (
    <Container maxWidth={"md"}>
      {loading && <LinearProgress />}

      <Box>
        <Box mt={6} mb={2}>
          <Typography variant="h4">Gemini</Typography>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                IMAGE
              </Typography>
              <Box sx={{ display: "unset" }}>
                <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                  <img src={src} style={{ width: "100%" }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                PROMPT
              </Typography>
              <Paper elevation={0}>
                <Box p={1}>
                  {Object.entries(templates).map(([k, v]) => (
                    <Chip
                      key={k}
                      size="small"
                      color="primary"
                      label={k}
                      component="a"
                      onClick={() => {
                        edit ? setPrompt(v) : sendToGemini(v);
                      }}
                      clickable
                      sx={{ marginRight: "3px" }}
                    />
                  ))}
                  <Chip
                    size="small"
                    color="secondary"
                    label="edit prompt"
                    component="a"
                    onClick={() => setEdit(true)}
                    clickable
                    sx={{ marginRight: "3px" }}
                  />
                </Box>
              </Paper>
              {edit && (
                <Box py={2}>
                  <TextField
                    id="prompt"
                    value={prompt}
                    name="prompt"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth
                    required
                    autoFocus
                    placeholder="Type something"
                    onChange={(e) => setPrompt(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            color="primary"
                            variant="contained"
                            disabled={prompt.length === 0}
                            onClick={() => sendToGemini(prompt)}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingRight: 0
                      },
                      backgroundColor: "#FFFFFF"
                    }}
                  />
                </Box>
              )}
              {summary && summary.complexityBasedLevel && (
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box p={2} sx={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h5">{summary.complexityBasedLevel}</Typography>
                    <Typography variant="overline">AI-based </Typography>
                  </Box>
                  <Box p={2} sx={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h5">{summary.figurineBasedLevel}</Typography>
                    <Typography variant="overline">Figurine-based </Typography>
                  </Box>
                </Box>
              )}
              {response && (
                <Box py={2}>
                  <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000", textAlign: "left" }}>
                    {JSON.stringify(response, null, 2)}
                  </pre>
                </Box>
              )}
              {summary && (
                <Box py={2}>
                  <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000", textAlign: "left" }}>
                    {JSON.stringify(summary, null, 2)}
                  </pre>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
