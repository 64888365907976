import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Chip, Grid, Link, Paper, Typography } from "@mui/material";
import { capitalize, formatPhoneNumber } from "../../common/component/util";
import { STORAGE_BUCKET } from "../../common/component/constants";
import moment from "moment";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function MetrobiDeliveryDetails(props) {
  const { provider } = props;
  if (provider && provider?.name === "metrobi") {
    const delivery = provider.data;
    return (
      <Box p={2} sx={{ backgroundColor: "#F7F7F7" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Delivery Provider
            </Typography>
            <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em", gap: "5px" }}>
              <Avatar style={{ width: "24px", height: "24px" }} src={`${STORAGE_BUCKET}/img/logo/${provider.name.toLowerCase()}.webp`}></Avatar>
              {provider.name}
            </Typography>
            {!provider.deliveryId && (
              <Typography color="error" variant="caption">
                Delivery Not Created!
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="overline" color="textSecondary">
                DRIVER
              </Typography>
              {delivery.data?.driver ? (
                <Box>
                  <Typography variant="body2">{delivery.data.driver.name}</Typography>
                  <Typography variant="body2">{formatPhoneNumber(delivery.data.driver.phone)}</Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="body2">Not yet assigned</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Note: drivers are typically assigned within 24 hours of the scheduled pickup
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {delivery && (
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Box>
                  <Typography variant="overline" color="textSecondary">
                    PICKUP
                  </Typography>
                </Box>
                {delivery.data.pickup_stop.completed_at ? (
                  <Typography variant="overline" display="block" gutterBottom>
                    {moment(delivery.data.pickup_stop.completed_at).format("ddd MMM D, h:mm a")} (actual)
                  </Typography>
                ) : (
                  <Typography variant="overline" display="block" gutterBottom>
                    {moment(delivery.data.pickup_stop.estimated_arrival_time).format("ddd MMM D, h:mm a")} (estimate)
                  </Typography>
                )}
                <Typography variant="body2">{delivery.data.pickup_stop.address}</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box>
                  <Box>
                    <Typography variant="overline" color="textSecondary">
                      DROPOFF
                    </Typography>
                  </Box>
                  {delivery.data.dropoff_stop.completed_at ? (
                    <Typography variant="overline" display="block" gutterBottom>
                      {moment(delivery.data.dropoff_stop.completed_at).format("ddd MMM D, h:mm a")} (actual)
                    </Typography>
                  ) : (
                    <Typography variant="overline" display="block" gutterBottom>
                      {moment(delivery.data.dropoff_stop.estimated_arrival_time).format("ddd MMM D, h:mm a")} (estimate)
                    </Typography>
                  )}
                  <Typography variant="body2">{delivery.data.dropoff_stop.address}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
         <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>API Response</AccordionSummary>
              <AccordionDetails>
                <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000" }}>{JSON.stringify(provider.data, null, 2)}</pre>
              </AccordionDetails>
            </Accordion>
      </Box>
    );
  } else {
    return <></>;
  }
}
