import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import SearchIcon from "@mui/icons-material/Search";
import { getQueryParam } from "../../common/component/util";
import RequestSummary from "../component/RequestSummary";
import EmailInput from "../../common/component/EmailInput";

export default function RequestSearch() {
  const [searchBy, setSearchBy] = useState("eventDt");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bakerId, setBakerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();

  useEffect(() => {
    const p = getQueryParam(window.location.search, "phone");
    if (p) {
      setSearchBy("phone");
      setPhone(p);
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    search();
  };

  const search = () => {
    setLoading(true);

    bkstApi(`/requests/?by=${searchBy}&fromDt=${fromDt}&toDt=${toDt}&email=${email}&phone=${phone}&bakerId=${bakerId}`).then((res) => {
      setResults(res.data.requests);
      setLoading(false);
    });
  };

  const rows = [];
  if (results) {
    for (const req of results) {
      rows.push(
        <Box mb={4} key={req.id}>
          <RequestSummary req={req} />
        </Box>
      );
    }
  }

  return (
    <Container maxWidth="md">
      <Box my={3}>
        <Typography variant="overline">SEARCH REQUESTS</Typography>
        <Paper>
          <Box p={3}>
            <Box pb={3} align="center">
              <FormControl component="fieldset">
                <RadioGroup row value={searchBy} name="searchBy" onChange={(e) => setSearchBy(e.target.value)}>
                  <FormControlLabel value="eventDt" control={<Radio required={true} />} label="Event Date" />
                  <FormControlLabel value="reqDt" control={<Radio required={true} />} label="Request Date" />
                  <FormControlLabel value="email" control={<Radio required={true} />} label="Email" />
                  <FormControlLabel value="phone" control={<Radio required={true} />} label="Phone" />
                  <FormControlLabel value="bakerId" control={<Radio required={true} />} label="BakerId" />
                </RadioGroup>
              </FormControl>
            </Box>
            <form onSubmit={submit}>
              <Box px={2} py={4} style={{ backgroundColor: "#f7f7f7" }} align="center">
                <Grid container spacing={3} style={{ justifyContent: "center" }}>
                  {(searchBy === "eventDt" || searchBy === "reqDt") && (
                    <Grid item xs={12} sm={5}>
                      <TextField
                        value={fromDt}
                        variant="outlined"
                        id="fromDt"
                        label="From Date"
                        name="fromDt"
                        onChange={(e) => setFromDt(e.target.value)}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {(searchBy === "eventDt" || searchBy === "reqDt") && (
                    <Grid item xs={12} sm={5}>
                      <TextField
                        value={toDt}
                        variant="outlined"
                        type="date"
                        id="toDt"
                        label="To Date"
                        name="toDt"
                        onChange={(e) => setToDt(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {searchBy === "email" && (
                    <Grid item xs={12} sm={10}>
                      <EmailInput value={email} onChange={(val) => setEmail(val)} required={true} />
                    </Grid>
                  )}
                  {searchBy === "phone" && (
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={phone}
                        name="phone"
                        variant="outlined"
                        id="phone"
                        label="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {searchBy === "bakerId" && (
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={bakerId}
                        name="bakerId"
                        variant="outlined"
                        id="bakerId"
                        label="BakerId"
                        onChange={(e) => setBakerId(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={2}>
                    <Button variant="contained" color="primary" type="submit" style={{ minHeight: "56px" }} fullWidth>
                      <SearchIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Paper>
        {loading && <LinearProgress />}
      </Box>
      {rows}
    </Container>
  );
}
