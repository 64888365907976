import { useEffect, useRef } from "react";

export default function CloudinaryUploadWidget(props) {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "draqf7k7f",
        uploadPreset: "bakery",
        folder: props.folder,
        sources: ["local", "camera"],
        max_files: 100,
        clientAllowedFormats: ["image"],
        maxFileSize: 10000000, // 10MB
        showPoweredBy: false,
        //autoMinimize: true,
        singleUploadAutoClose: true,
        showUploadMoreButton: false,
        text: {
          en: {
            local: {
              dd_title_multi: "Drag & Drop Photos"
              //browse: "SELECT PHOTOS"
            },
            notifications: {
              limit_reached: `Can't upload more than 100 photos`
            }
          }
        },
        styles: {
          palette: {
            window: "#ffffff",
            sourceBg: "#f4f4f5",
            windowBorder: "#90a0b3",
            tabIcon: "#000000",
            inactiveTabIcon: "#555a5f",
            menuIcons: "#555a5f",
            link: "#78374A",
            action: "#78374A",
            inProgress: "#0433ff",
            complete: "#78374A",
            error: "#F44235",
            textDark: "#000000",
            textLight: "#fcfffd"
          },
          fonts: {
            default: null,
            "sans-serif": {
              url: null,
              active: true
            }
          }
        }
      },
      function (error, result) {
        console.log(result);
        // Handle the result or error here
        if (result.event === "queues-end") {
          props.onComplete(result.info.files?.map((o) => o.uploadInfo.secure_url));
          widgetRef.current?.close();
        }
      }
    );
  }, [props]);

  return <span onClick={() => widgetRef.current.open()}>{props.children}</span>;
}
