import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Box, Chip, Link, Paper, Tooltip, Typography } from "@mui/material";
import QuoteItem from "../component/QuoteItem";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink } from "react-router-dom";
import bkstApi from "../../common/component/api";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import QuoteNotificationButton from "../component/QuoteNotificationButton";
import SmsBakerButton from "../../baker/component/SmsBakerButton";
import BlockIcon from "@mui/icons-material/Block";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import moment from "moment";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EnergySavingsLeafOutlinedIcon from "@mui/icons-material/EnergySavingsLeafOutlined";
import { Timeline, TimelineContent, TimelineConnector, TimelineSeparator } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import SaasQuote from "../component/SaaSQuote";
import { ExpandMoreOutlined } from "@mui/icons-material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

export default function QuoteAdmin(props) {
  const [quote, setQuote] = useState(props.quote);
  const [history, setHistory] = useState("");

  const changeStatus = (status) => {
    bkstApi.put(`/requests/${props.reqId}/quotes/${quote.id}`, { status }).then((res) => {
      setQuote(res.data);
    });
  };

  const getTimeline = (type) => {
    return (
      quote.events &&
      quote.events
        .filter((e) => e.type === type)
        .map((e) => moment(e.ts).format(`MMM DD, h:mm a`))
        .join(" | ")
    );
  };

  const getHistory = () => {
    return bkstApi(`/admin/site/${quote.id}/quote/${props.reqId}/history`).then((res) => {
      let arr = [];
      for (let o of res.data.history) {
        if (!o.updTs) {
          o.updTs = o.ts._seconds * 1000;
        }
        arr.push(o);
      }
      arr.push(quote);
      arr.sort((o1, o2) => +o2.updTs - +o1.updTs);

      setHistory(arr);
    });
  };

  const adminStyle = { display: "flex" };
  if (quote.status === "review") {
    adminStyle.borderRight = "3px solid #2980b9";
  } else if (quote.status === "pending") {
    adminStyle.borderRight = "3px solid #f39c12";
  }

  if (quote.baker.status === "offboard") {
    adminStyle.borderLeft = "3px solid #e74c3c";
  } else if (quote.baker.status === "scout") {
    adminStyle.borderLeft = "3px solid #34495e";
  }

  return (
    <Box>
      <Box align="right">
        <Tooltip title="View in DB">
          <IconButton
            target="_blank"
            href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Frequest~2F${props.reqId}~2Fquotes~2F${quote.id}`}
          >
            <FingerprintIcon />
          </IconButton>
        </Tooltip>
        {quote.status === "pending" && (
          <Tooltip title="Mark unavailable">
            <IconButton onClick={() => changeStatus("unavailable")}>
              <Badge color="secondary" variant="dot" invisible={!quote.reqTs}>
                <SentimentDissatisfiedIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.reqTs && !quote.resTs && (
          <Tooltip title="Block">
            <IconButton onClick={() => changeStatus("block")} disabled={quote.status === "block"}>
              <BlockIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <SmsBakerButton
          bakerId={quote.baker.id}
          link={`https://bakestreet.co/my/quote/${props.reqId}`}
          disabled={quote.baker.status === "offboard" || props.reqStatus === "closed"}
        />
        <QuoteNotificationButton
          reqId={props.reqId}
          quoteId={quote.id}
          disabled={
            quote.baker.status === "offboard" ||
            props.reqStatus === "closed" ||
            quote.status === "active" ||
            quote.status === "rbook" ||
            (quote.baker.rating > 0 && quote.baker.rating < 3.5)
          }
        />
        <Tooltip title="Edit Quote">
          <IconButton
            component={RouterLink}
            to={props.request.src === "direct" ? `/quote/${props.reqId}/edit` : `/request/${props.reqId}/quotes/${quote.id}/edit`}
            disabled={props.reqStatus === "closed"}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <QuoteItem quote={quote} reqId={props.reqId} request={props.request} experiment={props.experiment} />
      <Box mt={0.5} mb={8} style={adminStyle}>
        <Tooltip title={`${getTimeline("view")}`}>
          <Badge
            badgeContent={quote.events && quote.events.filter((e) => e.type === "view").length}
            color="secondary"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <Link to={`/baker/${quote.id}`} component={RouterLink} style={{ color: "inherit" }}>
              <Avatar src={`${BAKER_PIC_FOLDER}/${quote.id}/logo400.webp`} alt="" style={{ color: "inherit", height: "24px", width: "24px" }} />
            </Link>
          </Badge>
        </Tooltip>
        {quote.events && (
          <Tooltip title={`${getTimeline("expand")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "expand").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <AspectRatioIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.events && (
          <Tooltip title={`${getTimeline("phc")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "phc").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <ImageOutlinedIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.events && (
          <Tooltip title={`${getTimeline("flc")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "flc").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <EnergySavingsLeafOutlinedIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.events && (
          <Tooltip title={`${getTimeline("rvc")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "rvc").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <StarBorderOutlinedIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.events && (
          <Tooltip title={`${getTimeline("how")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "how").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <HelpOutlineIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {quote.events && (
          <Tooltip title={`${getTimeline("checkout")}`}>
            <IconButton>
              <Badge
                badgeContent={quote.events.filter((e) => e.type === "checkout").length}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <ShoppingCartOutlinedIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
          {quote.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
          {quote.quotedBy && (
            <img
              style={{ width: "32px", height: "32px", borderRadius: "16px", marginRight: "5px" }}
              src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${quote.quotedBy}.webp`}
            />
          )}
          {quote.uid && (
            <img
              style={{ width: "32px", height: "32px", borderRadius: "16px", marginRight: "5px" }}
              src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${quote.uid}.webp`}
            />
          )}
        </Box>
      </Box>
      <Box my={4}>
        <Accordion
          elevation={0}
          onChange={(e, expanded) => {
            if (expanded) {
              getHistory();
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>History</AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Box>
              {history && (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0
                    }
                  }}
                >
                  {history.map((o) => {
                    return (
                      <TimelineItem>
                        <TimelineSeparator>
                          <Avatar src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${o.uid}.webp`} />
                          <TimelineConnector />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent ml={1} mb={4} sx={{ backgroundColor: "#F7F7F7" }}>
                          <Box py={1}>
                            <Box mb={1}>
                              <Typography variant="overline" display="block">
                                {moment(o.updTs).format("LLLL")} <Chip size="small" label={o.status}></Chip>
                              </Typography>
                              {o.status === "pending" || o.status === "unavailable" ? (
                                <Paper elevation={0}>
                                  <Box p={2}>
                                    <Typography>Quote Requested</Typography>
                                  </Box>
                                </Paper>
                              ) : (
                                <SaasQuote request={props.request} quote={o} hideChat={true} />
                              )}
                            </Box>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
