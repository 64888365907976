import React from "react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import Images from "../../common/component/Images";
import IconText from "../../common/component/IconText";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { getDisplayName } from "../../common/component/util";
import moment from "moment";
import { bakeStreetSquareLogo } from "../../common/component/constants";
import { Link as RouterLink } from "react-router-dom";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import currency from "currency.js";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

export default function QuoteSummary(props) {
  const { request } = props;

  let status = "";
  let total = "";

  if (request.quote?.status === "pending") {
    status = "pending";
  } else {
    status = "sent";
    if (request.quote.items) {
      total = request.quote.items.reduce((acc, o) => +acc + (+o.total || +o.price), 0);
    } else if (request.quote) {
      total = request.quote.quote;
    }
  }

  return (
    <Paper key={request.id} elevation={0}>
      <Box fontSize={16} px={2} pt={2} pb={1} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <Images pics={request.pics || [request.pic1, request.pic2, request.pic3].filter((i) => i)} />
          </Grid>
          <Grid item xs={8} sm={8}>
            <Box>
              <Typography display="inline">{getDisplayName(request.firstName, request.lastName, request.name)}</Typography>
            </Box>
            <Box my={1}>
              <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                <EventOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                {moment(request.date).format("ddd, MMM DD, YYYY")}
              </Typography>
            </Box>
            {status === "pending" && request.quote.reqTs && (
              <Box my={1}>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                  <AccessTimeOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                  {`Requested ${moment(request.quote.reqTs._seconds * 1000).fromNow()}`}
                </Typography>
              </Box>
            )}
            {status === "sent" && request.quote.resTs && (
              <Box my={1}>
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".80rem", textTransform: "uppercase", color: "#888" }}>
                  <ScheduleSendOutlinedIcon style={{ fontSize: "1.2rem", marginRight: "7px" }} />
                  {`Quoted ${moment(request.quote.resTs._seconds * 1000).fromNow()}`}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid mt={1} container alignItems="center">
          <Grid item xs={6} sm={4}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {status === "pending" && request.quote.reqTs && (
                <IconText variant="overline" icon={AccessTimeIcon} text={moment(request.quote.reqTs._seconds * 1000).fromNow()} />
              )}
            </Box>
          </Grid>
          <Grid item sm={8} xs={6}>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
              {request.quote?.status === "paid" && (
                <IconButton size="small" component={RouterLink} to={`/order/${request.orderId}`}>
                  <LocalMallOutlinedIcon />
                </IconButton>
              )}
              {request.quote.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
              {request.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "24px", height: "24px" }} />}
              {status === "sent" && <Typography variant="body1">{total ? currency(total).format() : "unavailable"}</Typography>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
