import React from "react";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import PhoneField from "../../common/component/PhoneField";
import ShowMore from "../../common/component/ShowMore";
import EmailInput from "../../common/component/EmailInput";

export default function CustomerDetailEntry(props) {
  const { customer } = props;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={customer?.firstName}
              variant="outlined"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              onChange={(e) => props.onChange({ ...customer, firstName: e.target.value })}
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={customer?.lastName}
              variant="outlined"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              onChange={(e) => props.onChange({ ...customer, lastName: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl variant="outlined" fullWidth>
            <PhoneField name="Phone" value={customer?.phone} onChange={(phone) => props.onChange({ ...customer, phone })} required />
          </FormControl>
        </Grid>
      </Grid>
      <Box>
        <ShowMore fullWidth>
          <EmailInput value={customer?.email} onChange={(val) => props.onChange({ ...customer, email: val })} required={true} />
        </ShowMore>
      </Box>
    </Box>
  );
}
