import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  FormHelperText,
  IconButton,
  Link,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogTitle
} from "@mui/material";
import bkstApi from "../../common/component/api";
import ProductTagsAutocomplete from "./ProductTagsAutocomplete";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

export default function Row(props) {
  const [edit, setEdit] = useState(false);
  const [product, setProduct] = useState(props.product);
  const [title, setTitle] = useState(props.product.title || "");
  const [description, setDescription] = useState(props.product.description || "");
  const [status, setStatus] = useState(props.product.status || "");
  const [rank, setRank] = useState(props.product.rank || "");
  const [tags, setTags] = useState(props.product.tags || []);

  const save = () => {
    bkstApi({
      method: "PUT",
      url: `/admin/baker/${product.bakerId}/photo/${props.product.id}`,
      data: { title, description, status, rank, tags }
    }).then((res) => {
      setProduct(res.data);
      setEdit(false);
    });
  };

  return (
    <Box mb={4}>
      <Box align="center">
        <Link onClick={() => setEdit(true)}>
          <img src={product.img} style={{ width: "100%", aspectRatio: "1", objectFit: "cover", borderRadius: "4px" }} />
        </Link>
        <Box>
          <IconButton component={"a"} target="_blank" href={`/gemini?src=${encodeURIComponent(product.img)}`}>
            <TipsAndUpdatesOutlinedIcon />
          </IconButton>
          <Chip size="small" label={product.metadata?.image_quality}/>
          <Chip size="small" label={product.metadata?.image_aesthetics}/>
          {product.tags?.slice(0, 3).map((e, idx) => (
            <Chip key={idx} size="small" label={e} />
          ))}
          {product.tags?.length > 3 && <Chip size="small" label={`+${product.tags.length - 3} more`} />}
        </Box>
      </Box>
      <Dialog fullWidth open={edit} onClose={() => setEdit(false)}>
        <DialogTitle>
          Edit Photo{" "}
          <Link
            target="_blank"
            href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Fbaker~2F${product.bakerId}~2Fphoto~2F${props.product.id}`}
          >
            [DB]
          </Link>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <img src={product.img} style={{ height: "250px", maxWidth: "100%", objectFit: "contain" }} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      id="title"
                      value={title}
                      label="Title"
                      name="title"
                      variant="outlined"
                      onChange={(e) => setTitle(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        value={status}
                        labelId="status-label"
                        label="Status"
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                        <MenuItem value="DRAFT">DRAFT</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="rank"
                      value={rank}
                      label="Rank"
                      name="rank"
                      type="number"
                      variant="outlined"
                      onChange={(e) => setRank(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <ProductTagsAutocomplete tags={tags} onChange={(val) => setTags(val)} multiple={true} />
                  <FormHelperText>Tag theme(e.g. jungle) & design elements (e.g. tree, elephant)</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {product.metadata && (
                  <Box py={2}>
                    <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000", textAlign: "left" }}>
                      {JSON.stringify(product.metadata, null, 2)}
                    </pre>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={(e) => setEdit(false)} style={{ marginRight: "1rem" }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={save}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
