import moment from "moment";
import currency from "currency.js";

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  var match = phoneNumber.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return value;
  }
};

export function getQueryParam(queryStr, p) {
  const arr = queryStr.replace("?", "").split("&");
  for (const a of arr) {
    if (a.startsWith(`${p}=`)) {
      return decodeURIComponent(a.substring(p.length + 1));
    }
  }
  return undefined;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function createSnippets(productType, shape, size, servings) {
  const data = [];
  if (!productType || productType === "Cake") {
    data.push(`${shape}, ${size} cake`);
    data.push(`${servings} servings`);
  } else {
    data.push(`${servings} ${productType}`);
  }

  return data;
}

export function getDisplayName(firstName, lastName, name) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else {
    return name;
  }
}

export function isPWA() {
  return window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function getDefaultQuoteExpiration(eventDate) {
  if (eventDate) {
    const daysToEvent = moment(eventDate).diff(moment(), "days");
    if (daysToEvent < 3) {
      return moment().add(2, "hours");
    } else if (daysToEvent < 7) {
      return moment().add(1, "days");
    } else if (daysToEvent < 21) {
      return moment().add(2, "days");
    } else if (daysToEvent < 45) {
      return moment().add(3, "days");
    } else {
      return moment().add(5, "days");
    }
  } else {
    return moment().add(1, "days");
  }
}

export function getAmountWithTxnFee(amount) {
  return currency(amount).add(currency(amount).multiply(0.045)).toString();
}

export function getAmountWithoutTxnFee(amount) {
  return currency(amount).divide(1.045).toString();
}

export function getOriginalAmount(amount, includeFees) {
  return (includeFees ? getAmountWithoutTxnFee(amount) : amount) || "";
}

export function getStartTime(str) {
  let hour = 0;
  const time = (str?.trim() || "").toLowerCase();

  const amIdx = time.indexOf("am");
  const noonIdx = time.indexOf("noon");
  const pmIdx = time.indexOf("pm");

  // find first occurence
  const arr = [amIdx, noonIdx, pmIdx].filter((idx) => idx >= 0);
  if (arr.length > 0) {
    // split time string at the first match
    const splitIdx = Math.min(...arr);
    if (noonIdx === splitIdx) {
      hour = 12;
    } else {
      // split string and extract hour
      hour = parseInt(time.substring(0, splitIdx));
      if (pmIdx === splitIdx) {
        if (hour !== 12) {
          hour = hour + 12;
        }
      }
    }
  }

  return hour;
}

export function sortByOrderDateTime(o1, o2) {
  let result = 0;

  try {
    result = o1.fulfillment.date.localeCompare(o2.fulfillment.date);
    if (result === 0) {
      // if date is same, sort by time
      result = getStartTime(o1.fulfillment.time) - getStartTime(o2.fulfillment.time);
    }
  } catch (error) {
    console.log("invalid order date/time");
  }
  return result;
}

export function getCustomerName(customer) {
  if (customer.firstName) {
    return `${customer.firstName} ${customer.lastName || ""}`;
  } else if (customer.name && customer.lastName) {
    return `${customer.name} ${customer.lastName}`;
  } else {
    return customer.name || "";
  }
}

export function sortNotifications(o1, o2) {
  if (o1.status === o2.status) {
    if (o1.status === "open") {
      if (o1.priority > o2.priority) {
        return 1;
      } else if (o1.priority < o2.priority) {
        return -1;
      } else {
        return o1.link.localeCompare(o2.link);
      }
    } else {
      // closed status sorted by ts
      return o1.ts._seconds < o2.ts._seconds ? 1 : -1;
    }
  } else {
    return o1.status === "closed" ? 1 : -1;
  }
}

export function getVariantTotal(variant) {
  let total = 0;
  for (const o of Object.values(variant)) {
    if (o?.pricing) {
      total += +o.pricing.amount;
    } else if (typeof o === "object") {
      total += getVariantTotal(o);
    }
  }
  return +total;
}

export function generateSizeSummary(variant) {
  let summary = "";

  if (variant) {
    if (variant.shape) {
      summary += `${variant.shape} • `;
    }
    if (variant.size) {
      summary += `${variant.size} • `;
    }
    if (variant.servings) {
      summary += `${variant.servings} servings`;
    }
  }

  return summary;
}

export function generateFlavorSummary(arr) {
  let summary = "";
  if (Array.isArray(arr)) {
    for (const o of arr) {
      if (o.name) {
        summary = `Flavor: ${o.name}`;
      }

      if (o.filling) {
        summary += ` • Filling: ${o.filling}`;
      }
    }
  } else if (typeof arr === "object") {
    if (arr.base) {
      summary = `Flavor: ${arr.base.name}`;
    }
    if (arr.filling) {
      summary += ` • Filling: ${arr.filling.name}`;
    }
  }

  return summary;
}

export function getProductTypes(model) {
  return Object.keys(model);
}

export function getProductSubtypes(model, productType) {
  return Object.keys(model[productType]);
}

export function getItemMetadata(product) {
  let [minPrice, maxPrice, leadTime] = [0, 0, 0];
  let dietary = new Set();
  if (product.options?.size) {
    for (const variant of product.options.size) {
      const price = +variant.pricing.amount;
      if (minPrice === 0) {
        minPrice = price;
      } else if (price <= minPrice) {
        minPrice = price;
      } else if (price >= maxPrice) {
        maxPrice = price;
      }

      const title = variant.size?.toLowerCase();
      if (title.includes("vegan")) {
        dietary.add("VG");
      }

      if (title.includes("gluten") && title.includes("free")) {
        dietary.add("GF");
      }

      leadTime = product.rules?.date?.leadTime || 0;
    }
  }

  return { price: { min: minPrice, max: maxPrice }, dietary: Array.from(dietary), leadTime };
}

export function isTruncated(element) {
  if (element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)) {
    return true;
  } else {
    return false;
  }
}

export function normalizeFlavor(value) {
  if (Array.isArray(value)) {
    return value[0];
  } else if (typeof value === "object") {
    return { name: value?.base?.name, filling: value?.filling?.name };
  } else {
    return "";
  }
}

export function convertLineItemToOrderDetails(item) {
  let o = {
    decor: item.description || "",
    pics: item.images || [],
    productType: item.type,
    tags: item.tags || [],
    title: item.title,
    size: "",
    shape: "",
    servings: "",
    flavor: "",
    filling: "",
    frosting: "",
    inscription: ""
  };

  if (item.variant) {
    if (item.variant.size) {
      o.size = item.variant.size.size || "";
      o.shape = item.variant.size.shape || "";
      o.servings = item.variant.size.servings || "";
    }
    if (item.variant.flavor) {
      o.flavor = item.variant.flavor[0].name || "";
      o.filling = item.variant.flavor[0].filling || "";
    }
    if (item.variant.frosting) {
      o.frosting = item.variant.frosting.name || "";
    }
  }
  if (item.customization) {
    o.inscription = item.customization.inscription.text;
  }

  return o;
}

export function doObjectsMatch(o1, o2) {
  const replacer = (key, value) =>
    value instanceof Object && !(value instanceof Array)
      ? Object.keys(value)
          .sort()
          .reduce((sorted, key) => {
            sorted[key] = value[key];
            return sorted;
          }, {})
      : value;

  const j1 = JSON.stringify(o1, replacer);
  const j2 = JSON.stringify(o2, replacer);
  console.log("compare objects", j1, j2);

  return j1 === j2;
}

export function getNormalizedUtmSrc(utm) {
  let utmSrc = `${utm.utms}:${utm.utmc}`;

  // normalize utm
  if (utmSrc.includes("google")) {
    utmSrc = "google";
  } else if (utmSrc.includes("instagram")) {
    utmSrc = "instagram";
  } else if (utmSrc.includes("facebook")) {
    utmSrc = "facebook";
  } else if (utmSrc.includes("yelp")) {
    utmSrc = "yelp";
  } else if (utmSrc.includes("yahoo")) {
    utmSrc = "yahoo";
  }

  return utmSrc;
}

export function getFormattedAddressAsArray(location, includeUnit) {
  let arr = [];

  if (location) {
    let line1 = location.street;
    if (includeUnit && location.unit) {
      line1 += `, ${location.unit}`;
    }
    arr.push(line1);
    arr.push(`${location.city}, ${location.state} ${location.zip}`);
  }
  return arr;
}

export function isEmailValid(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}
